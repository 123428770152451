import React, { Component } from 'react'
import { connect } from 'react-redux'
import isEqual from 'react-fast-compare'
import moment from 'moment'
import { logInUserInfo } from '../../Actions/Users'
import { withLocalize } from 'react-localize-redux'
import Layout from '../../wrapper'
import Loader from '../../Layout/Loader'
import { updateTemplates, removeTemplate , updateTemplate } from '../../Actions/Templates'
import Notifications from 'react-notification-system-redux'
import ReportGenerator from './ReportGenerator'
import Users from './Users'
import { checkPrivileges } from '../../Helpers'
import CustomDialog from '../../Components/common/Dialog'
import 'react-table/react-table.css'
import EmptyState from '../../Components/common/EmptyState'
import ConfirmDialoag from '../../Components/common/ConfirmDialoag'

class Reports extends Component {
  constructor (props) {
    super(props)
    this.state = {
      templatesCall: false,
      form: null,
      openGenerator: true,
      assignmentsModal: false,
      selectedTemplateId: '',
      onCreateSelected:false,
      deleteDialog:false,
      statusDialog:false,
    }
    this.openReportGenerator = this.openReportGenerator.bind(this)
  }
  componentWillMount () {
    if (
      this.props.logInUser.id &&
      this.props.templates &&
      !this.props.templates.length &&
      this.state.templatesCall === false
    ) {
      let query = 'all=true&userId=' + this.props.logInUser.id
        if(this.props.logInUser.id === 1){
          query = 'all=true'
        }
      this.setState({ templatesCall: true }, () => {
        fetch('/api/report/templates?' + query).then(response => {
          if (response.ok) {
            response.json().then(templates => {
              if (templates.status === 'success') {
                this.props.dispatch(updateTemplates(templates.data))
              }
              else if(templates?.statusCode === '440'){
                window.location.replace('/logout')
              }
              else if(templates.statusCode){
                var err = templates?.message.split(':')
                err[1] =err[1].replace(')', "")
                this.props.dispatch(
                  Notifications.error({
                    message: this.props.translate(err[1]),
                    autoDismiss: 5,
                    location: this.props.location
                  })
                )
              }
            })
          }else{
            throw response
          }
        })
        .catch(e => {
          this.props.dispatch(Notifications.error({
            message: 'somethingWentWrong',
            autoDismiss: 5
          }))
        })
      })
    }
  }
  componentWillUnmount () {
    this.props.dispatch(Notifications.removeAll())
  }
  shouldComponentUpdate (nextProps, nextState) {
    return !isEqual(nextProps, this.props) || !isEqual(nextState, this.state)
  }

  UNSAFE_componentWillReceiveProps (NextProps) {
    if (
      NextProps.logInUser.id &&
      NextProps.templates &&
      !NextProps.templates.length &&
      this.state.templatesCall === false
    ) {
     if(this.props.logInUser.id){

       let query = 'all=true&userId=' + this.props.logInUser.id
     if(this.props.logInUser.id === 1){
       query = 'all=true'
     }
       this.setState({ templatesCall: true }, () => {
         fetch('/api/report/templates?' + query).then(response => {
           if (response.ok) {
             response.json().then(templates => {
               if (templates.status === 'success') {
                 NextProps.dispatch(updateTemplates(templates.data))
               }
               else if(templates?.statusCode === '440'){
                window.location.replace('/logout')
              }
               else if(templates.statusCode){
                var err = templates?.message.split(':')
                err[1] =err[1].replace(')', "")
                this.props.dispatch(
                  Notifications.error({
                    message: this.props.translate(err[1]),
                    autoDismiss: 5,
                    location: this.props.location
                  })
                )
              }
             })
           }else{
             throw response
           }
         })
         .catch(e => {
          this.props.dispatch(Notifications.error({
            message: 'somethingWentWrong',
            autoDismiss: 5
          }))
        })
       })
     }
    }
  }

  openReportGenerator () {
    this.setState({ openGenerator: true })
  }

  deleteDialougConfirmation = item =>{
    this.setState({
      deleteDialog:true,
      deleteItem:item
    })
}

  deleteTemplate = () => {
    let id = this.state?.deleteItem?.id
    if (id) {
      fetch('/api/report/templates/' + id, {
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      })
        .then(response => {
            response.json().then(temp => {
              if (temp.status === 'success') {
                this.props.dispatch(removeTemplate({ id }))
                this.props.dispatch(
                  Notifications.success({
                    message: this.props.translate('rptmplDelete'),
                    autoDismiss: 5,
                    location: this.props.location
                  })
                )
              } 
              else if(temp?.statusCode === '440'){
                window.location.replace('/logout')
              }
              else if(temp.statusCode){
                var err = temp?.message.split(':')
                err[1] =err[1].replace(')', "")
                this.props.dispatch(
                  Notifications.error({
                    message: this.props.translate(err[1]),
                    autoDismiss: 5,
                    location: this.props.location
                  })
                )
              }
              else {
                throw response
              }
            })
            this.setState({
              deleteDialog:false,
              assignmentsModal: false,
              form:'',
              onCreateSelected: true,
            })
          
        })
        .catch(e => {
          this.props.dispatch(
            Notifications.error({
              message: this.props.translate('somthingWentWrongMessage'),
              autoDismiss: 5,
              location: this.props.location
            })
          )
        //   if(e && e.text){
        //   e.text().then(err => {
        //     if (err.includes('Default Report Template can not be modified')) {
        //       this.props.dispatch(
        //         Notifications.error({
        //           message: this.props.translate('defaultTempCantModified'),
        //           autoDismiss: 5,
        //           location: this.props.location
        //         })
        //       )
        //     } else if (err.includes('Not enough privileges')) {
        //       this.props.dispatch(
        //         Notifications.error({
        //           message: this.props.translate('lackOfPrivileges'),
        //           autoDismiss: 5,
        //           location: this.props.location
        //         })
        //       )
        //     } else {
        //       this.props.dispatch(
        //         Notifications.error({
        //           message: this.props.translate('somthingWentWrongMessage'),
        //           autoDismiss: 5,
        //           location: this.props.location
        //         })
        //       )
        //     }
        //   })
        // }
        })
    }
  }

  resetForm = () => {
    this.setState({ form: null })
  }

  updateTemplate = item => {
    this.setState(
      {
        form: ''
      },
      () => this.setState({ form: item, assignmentsModal:false ,onCreateSelected:false})
    )
  }
  changeTemplateStatus = item =>{
      this.setState({
        statusDialog:true,
        selectedTemplate:item
      })
  }

  changeItemStatus = () =>{
    if(!this.state.selectedTemplate.rpTmpl.active){
        fetch(`api/report/templates/enable?id=${this.state.selectedTemplate.rpTmpl.id}`, {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          }
        })
      .then(response =>{
            response.json().then(res=>{
              if(res.status === 'success'){
                this.setState({
                  selectedTemplate:{
                    ...this.state.selectedTemplate,
                    rpTmpl:{
                      ...this.state.selectedTemplate.rpTmpl,
                      active:true
                    }
                  }
                    },()=>{
                      this.props.dispatch(updateTemplate(this.state.selectedTemplate))
                      this.updateTemplate(this.state.selectedTemplate)
                    })
                      this.props.dispatch(
                        Notifications.success({
                          message: this.props.translate('reportTemplateEnabled'),
                          autoDismiss: 5,
                          location: this.props.location
                                })
                          )
                  }
                  else if(res?.statusCode === '440'){
                    window.location.replace('/logout')
                  }
                  
                  else if(res.statusCode){
                    var err = res?.message.split(':')
                    err[1] =err[1].replace(')', "")
                    this.props.dispatch(
                      Notifications.error({
                        message: this.props.translate(err[1]),
                        autoDismiss: 5,
                        location: this.props.location
                      })
                    )
                  }
                  else {
                    throw response
                  }
              })

        })
      .catch(e => {
        console.log('e =',e)
        // if(e && e.text){
        // e.text().then(err => {
            // this.props.dispatch(
            //   Notifications.error({
            //     message: this.props.translate('somthingWentWrongMessage'),
            //     autoDismiss: 5,
            //     location: this.props.location
            //   })
            // )
      //   })
      // }
    })

    }
    else{
      fetch(`api/report/templates/disable?id=${this.state.selectedTemplate.rpTmpl.id}`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      })
    .then(response =>{
      response.json().then(res=>{
        if(res.status === 'success'){
          this.setState({
            selectedTemplate:{
              ...this.state.selectedTemplate,
              rpTmpl:{
                ...this.state.selectedTemplate.rpTmpl,
                active:false
              }} },()=>{
                this.props.dispatch(updateTemplate(this.state.selectedTemplate))
                this.updateTemplate(this.state.selectedTemplate)})
                this.props.dispatch(
                  Notifications.success({
                    message: this.props.translate('reportTemplateDisabled'),
                    autoDismiss: 5,
                    location: this.props.location
                  })
              )
            }else if(res?.statusCode === '440'){
              window.location.replace('/logout')
            }
            else if(res.statusCode){
            var err = res?.message.split(':')
            err[1] =err[1].replace(')', "")
            this.props.dispatch(
              Notifications.error({
                message: this.props.translate(err[1]),
                autoDismiss: 5,
                location: this.props.location
              })
            )
          }
          else {
            throw response
          }
            })
       })
        .catch(e => {
          console.log('e =',e)
          // if(e && e.text){
          // e.text().then(err => {
              // this.props.dispatch(
              //   Notifications.error({
              //     message: this.props.translate('somthingWentWrongMessage'),
              //     autoDismiss: 5,
              //     location: this.props.location
              //   })
              // )
        //   })
        // }
        })
    }
    this.setState({
      statusDialog:false,
    })
  }


  onTemplateAssignments = item => {
    this.setState({
      selectedTemplateId: item,
      assignmentsModal: true,
      onCreateSelected:false
    })
  }
  

  onCancel = () => {
    this.setState({
      assignmentsModal: false,
      statusDialog:false,
      deleteDialog:false,
    })
  }
  onCreate = ()=> {
    this.setState({
      assignmentsModal: false,
      form:'',
      onCreateSelected: true,
    })
  }
  render () {
    const { selectedTemplate } = this.state
    if (checkPrivileges('rptmpl')) {
      if (this.props.logInUser) {
        if (this.props.logInUser.userType === 6) {  //distributor cannot have access of template generator
          this.props.history.push('/*')
        } else {
          return (
            <>
              <Layout
                {...this.props}
                {...this.state}
                deleteTemplate={this.deleteDialougConfirmation}
                updateTemplate={this.updateTemplate}
                changeTemplateStatus={this.changeTemplateStatus}
                changeTemplateError={this.changeTemplateError}
                onTemplateAssignments={this.onTemplateAssignments}
                openReportGenerator={this.openReportGenerator}
                onCreate = {this.onCreate}
                logInUser={this.props.logInUser}
              >
                {this.state.loading && <Loader />}
                {this.props.logInUser &&
                  this.props.logInUser.id &&
                  !this.state.assignmentsModal && 
                  this.state.form && (
                    <ReportGenerator
                      mode='update'
                      item={this.state.form}
                      onUpdate={this.resetForm}
                      dispatch={this.props.dispatch}
                      logInUser={this.props.logInUser}
                      translate={this.props.translate}
                    />
                  )}

                {!this.state.onCreateSelected &&
                !this.state.form &&
                this.props.logInUser &&
                  this.props.logInUser.id &&
                  !this.state.assignmentsModal && 
                   (
                    <EmptyState text={this.props.translate('pleaseSelectTemplate')} />
                  )}

                {this.state.onCreateSelected &&
                  this.props.logInUser &&
                  this.props.logInUser.id &&
                  !this.state.assignmentsModal && 
                   (
                    <ReportGenerator
                      mode='create'
                      dispatch={this.props.dispatch}
                      logInUser={this.props.logInUser}
                      translate={this.props.translate}
                    />
                  )}

                {this.state.assignmentsModal && (
                    <Users
                      selectItemId={this.state.selectedTemplateId}
                      templates={this.props.templates}
                      translate={this.props.translate}
                      logInUser={this.props.logInUser}
                      themecolors={this.props.themecolors}
                      ServerSetting={this.props.ServerSetting}
                    />
                )}
                {this.state.statusDialog && (
                  <ConfirmDialoag
                      onCancel={this.onCancel}
                      onOk={this.changeItemStatus}
                      title={selectedTemplate.rpTmpl && selectedTemplate.rpTmpl.active ? this.props.translate('areYousuretoDeActivateTemplate?') : this.props.translate('areYousuretoActivateTemplate?')}
                      children={selectedTemplate&&selectedTemplate.rpTmpl&&selectedTemplate.rpTmpl.name}
                  />
                )}
                {this.state.deleteDialog && (
                  <ConfirmDialoag
                      onCancel={this.onCancel}
                      onOk={this.deleteTemplate}
                      title={this.props.translate('areYouSureToDeleteTemplate?')}
                      children={this.state.deleteItem?.name || null}
                  />
                )}


              </Layout>
            </>
          )
        }
      } else {
        fetch('/api/session?app=2bd5bd10345cd1555b14c221ec6e8647c46ee21e8ee45312', {
          headers: { Accept: 'application/json; odata=verbose' }
        }).then(response => {
          if (response.ok) {
            response.json().then(res => {
              if(res.status ==='success'){ 
                let userInfo = res.data
                this.props.setActiveLanguage(userInfo?.attributes?.lang || 'en')
                //API privilige call
                if(userInfo?.roleId){
  
                  fetch(`/api/privileges?roleId=${userInfo.roleId}`, {
                    method: 'Get'
                  })
                    .then(response => {
                      if (response.ok) {
                        response.json().then(res1 => {
                          if(res1.status ==='success'){ 
                            let privileges = res1.data
                            let privilegeKeys = []
                            privileges.map(item => {
                              privilegeKeys.push(item.key)
                            })
                            localStorage.setItem(
                              'privileges',
                              JSON.stringify({ ...privilegeKeys })
                            )
                            if (privileges.length) {
                              localStorage.setItem('userToken', '')
                              this.props.history.push('/templateGenerator')
                            } else {
                              this.setState({
                                loading: false,
                                loginErrorMessage: 'lackOfPrivileges'
                              })
                            }
                          }
                          else if(res1?.statusCode === '440'){
                            window.location.replace('/logout')
                          }
                          else if(res1.statusCode){
                            var err = res1?.message.split(':')
                            err[1] =err[1].replace(')', "")
                            this.props.dispatch(
                              Notifications.error({
                                message: this.props.translate(err[1]),
                                autoDismiss: 5,
                                location: this.props.location
                              })
                            )
                          }
                        })
                      } else {
                        throw response
                      }
                    })
                    .catch(e => {
                      console.log('e =',e)
                      // this.props.dispatch(Notifications.error({
                      //   message: 'somethingWentWrong',
                      //   autoDismiss: 5
                      // }))
                    })
                }
  
                //Privilige call ends
                this.props.dispatch(logInUserInfo(userInfo))

              }
              else if(res?.statusCode === '440'){
                window.location.replace('/logout')
              }
              else if(res.statusCode){
                var err = res?.message.split(':')
                err[1] =err[1].replace(')', "")
                this.props.dispatch(
                  Notifications.error({
                    message: this.props.translate(err[1]),
                    autoDismiss: 5,
                    location: this.props.location
                  })
                )
                this.props.history.push('/logout')
              }
            })
          } else {
            this.props.history.push('/logout')
              throw response
            }
          }).catch(e => {
            this.props.dispatch(Notifications.error({
              message: 'somethingWentWrong',
              autoDismiss: 5
            }))
          })
        return null
      }
    } else {
      return <h1>Page Not Found</h1>
    }
  }
}

const mapStateToProps = state => {
  return {
    devices: state.devices,
    logInUser: state.logInUsers,
    groups: state.groups,
    templates: state.templates,
    themecolors: state.themecolors,
    ServerSetting: state.ServerSetting
  }
}

export default connect(mapStateToProps)(withLocalize(Reports))
