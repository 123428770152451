import React, { Component } from 'react'
import { connect } from 'react-redux'
import Layout from './../../wrapper'
import { AcManagements } from '../../Components/AccountManagements/accountManagements'
import { withLocalize } from 'react-localize-redux'
import Notifications from 'react-notification-system-redux'
import { logInUserInfo } from '../../Actions/Users'
import { ServerInfo } from '../../Actions/serverSetting'
import { checkPrivileges } from '../../Helpers'

class AccountManagements extends Component {
  constructor (props) {
    super(props)
    this.state = {
      userInfo: {},
      userFetch: false
    }
  }

  componentWillMount () {
    this.props.dispatch(Notifications.removeAll())
    if (!this.props.ServerSetting) {
      fetch('/api/server').then(response => {
        if (response.ok) {
          response.json().then(res => {
            if(res.status ==='success'){   
              let server = res.data
              this.props.dispatch(ServerInfo(server))
            }
            else if(res?.statusCode === '440'){
              window.location.replace('/login')
            }
            else if(res.statusCode){
              var err = res?.message.split(':')
              err[1] =err[1].replace(')', "")
              this.props.dispatch(
                Notifications.error({
                  message: this.props.translate(err[1]),
                  autoDismiss: 5,
                  location: this.props.location
                })
              )
            }
          })
        }else{
          throw response
        }
      })  .catch(e => {
        this.props.dispatch(Notifications.error({
          message: 'somethingWentWrong',
          autoDismiss: 5
        }))
      })
    }
  }

  render () {
    if (checkPrivileges('user')) {
      if (this.props.logInUser) {
        return (
          <Layout
            {...this.props}
            noSidebar
          >
            <AcManagements {...this.props} />
          </Layout>
        )
      } else
        fetch('/api/session?app=2bd5bd10345cd1555b14c221ec6e8647c46ee21e8ee45312', {
          headers: { Accept: 'application/json; odata=verbose' }
        }).then(response => {
          if (response.ok) {
            response.json().then(res => {
              if(res.status ==='success'){  
                let userInfo = res.data
                this.props.setActiveLanguage(userInfo?.attributes?.lang || 'en')
                this.props.dispatch(logInUserInfo(userInfo))
              }
              else if(res?.statusCode === '440'){
                window.location.replace('/login')
              }
              else if(res.statusCode){
                var err = res?.message.split(':')
                err[1] =err[1].replace(')', "")
                this.props.dispatch(
                  Notifications.error({
                    message: this.props.translate(err[1]),
                    autoDismiss: 5,
                    location: this.props.location
                  })
                )
                this.props.history.push('/logout')
              }
            })
          } else {
            this.props.history.push('/logout')
            throw response
          }
        })  
        .catch(e => {
          this.props.dispatch(Notifications.error({
            message: 'somethingWentWrong',
            autoDismiss: 5
          }))
        })
      return null
    } else {
      return <h1>Page Not Found</h1>
    }
  }
}

const mapStateToProps = state => ({
  ServerSetting: state.ServerSetting,
  logInUser: state.logInUsers
})

export default connect(mapStateToProps)(withLocalize(AccountManagements))
