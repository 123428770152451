// First we need to import axios.js
import axios, {AxiosInstance} from 'axios';

const instance = axios.create({
});

instance.interceptors.request.use(request => {

    return request;
}, error => {
    // return Promise.reject(error);
});
instance.interceptors.response.use(response => {    
    if(response.data.status === 'success'){ 
        return response.data.data;
    }else{
        console.log("error ==",response);
        // return response.data;
        return Promise.reject( response.data);
    }
}, error => {
    console.log("reject error ==",error);
    return Promise.reject(error);
});

export default instance;