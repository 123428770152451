import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withLocalize } from 'react-localize-redux'
import Notifications from 'react-notification-system-redux'
import Layout from './../../wrapper'
import { logInUserInfo } from '../../Actions/Users'
import { UsersList } from '../../Components/Users/usersModalforSuperAdmin'
import { checkPrivileges } from '../../Helpers'

class Users extends Component {
  constructor (props) {
    super(props)
    this.state = {
      isVisable: true,
      sessionCallTrack: true
    }
  }
  componentWillMount () {
    this.props.dispatch(Notifications.removeAll())
    //   if (
    //     localStorage.getItem('adminToken')
    //   ) {
    //     window.location.replace('/login')
    //   }
  }

  controleSession = () => {
    if (localStorage.getItem('userInfo')) {
      fetch('/api/session', {
        method: 'DELETE'
      }).then(res => {
        let response = res.data
        if (response.ok) {
          this.props.history.push('/logout')
        }else{
          throw response
        }
      }).catch(e => {})
    }
  }

  render () {
    if (checkPrivileges('user')) {
      if (this.props.logInUser) {
        return (
          <Layout {...this.props}>
            <UsersList history={this.props.history} />
          </Layout>
        )
      } else {
        if (this.state.sessionCallTrack) {
          this.setState(
            {
              sessionCallTrack: false
            },
            () => {
              fetch('/api/session?app=2bd5bd10345cd1555b14c221ec6e8647c46ee21e8ee45312', {
                headers: { Accept: 'application/json; odata=verbose' }
              }).then(response => {
                if (response.ok) {
                  response.json().then(res => {
                    if(res.status ==='success'){ 
                      let userInfo = res.data
                      this.props.setActiveLanguage(
                        userInfo?.attributes?.lang || 'en'
                      )
                      // fetch call for Api  privileges
                      if(userInfo?.roleId){
                        fetch(`/api/privileges?roleId=${userInfo.roleId}`, {
                          method: 'Get'
                        })
                          .then(response => {
                            if (response.ok) {
                              response.json().then(res1 => {
                                if(res1.status ==='success'){ 
                                  let privileges = res1.data
                                  let privilegeKeys = []
                                  privileges.map(item => {
                                    privilegeKeys.push(item.key)
                                  })
                                  localStorage.setItem(
                                    'privileges',
                                    JSON.stringify({ ...privilegeKeys })
                                  )
                                  if (privileges.length) {
                                    localStorage.setItem('userToken', '')
                                    this.props.history.push('/users')
                                  } else {
                                    this.setState({
                                      loading: false,
                                      loginErrorMessage: 'lackOfPrivileges'
                                    })
                                  }
                                }
                                else if(res?.statusCode === '440'){
                                  window.location.replace('/login')
                                }
                                else if(res.statusCode){
                                  var err = res?.message.split(':')
                                  err[1] =err[1].replace(')', "")
                                  this.props.dispatch(
                                    Notifications.error({
                                      message: this.props.translate(err[1]),
                                      autoDismiss: 5,
                                      location: this.props.location
                                    })
                                  )
                                }
                              })
                            } else {
                              throw response
                            }
                          })
                          .catch(e => {
                            console.log('e =',e)
                            // this.props.dispatch(Notifications.error({
                            //   message: 'somethingWentWrong',
                            //   autoDismiss: 5
                            // }))
                          })
                      }
  
                      this.props.dispatch(logInUserInfo(userInfo))

                    }
                    else if(res?.statusCode === '440'){
                      window.location.replace('/login')
                    }
                    else if(res.statusCode){
                      var err = res?.message.split(':')
                      err[1] =err[1].replace(')', "")
                      this.props.dispatch(
                        Notifications.error({
                          message: this.props.translate(err[1]),
                          autoDismiss: 5,
                          location: this.props.location
                        })
                      )
                      this.props.history.push('/logout')
                    }
                  })
                } else {
                  this.props.history.push('/logout')
                    throw response
                  }
                }).catch(e => {
                  this.props.dispatch(Notifications.error({
                    message: 'somethingWentWrong',
                    autoDismiss: 5
                  }))
                })
            }
          )
        }
        return null
      }
    } else if (
      checkPrivileges('account') &&
      !checkPrivileges('device') &&
      !checkPrivileges('group')
    ) {
      this.controleSession()
      return null
    } else {
      return defaultPages.map(page => {
        if (checkPrivileges(page)) {
          if (page === 'device') {
            this.props.history.push(`/units`)
          } else {
            this.props.history.push(`/${page}s`)
          }
        }
      })
    }
  }
}

const mapStateToProps = state => ({
  ServerSetting: state.ServerSetting,
  logInUser: state.logInUsers
})

export default connect(mapStateToProps)(withLocalize(Users))

const defaultPages = ['account', 'device', 'group']
