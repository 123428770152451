import React, { Component } from 'react'
import { connect } from 'react-redux'
import Layout from '../../wrapper'
import { RoleModal } from '../../Components/RoleManagement'
import { logInUserInfo } from '../../Actions/Users'
import EmptyState from '../../Components/common/EmptyState'
import { withLocalize } from 'react-localize-redux'
import Notifications from 'react-notification-system-redux'
import { getPrivileges } from '../../Actions/Privileges'
import { checkPrivileges, isOwnerOrDealer } from '../../Helpers'
import Loader from '../../Layout/Loader'

class RoleManagement extends Component {
  constructor (props) {
    super(props)
    this.state = {
      isVisable: false,
      loader:false,
      activeOperation: '',
      selectedItem: '',
      itemPrivileges: []
    }
  }
  componentWillMount () {
    this.props.dispatch(Notifications.removeAll())
     if(this.props.logInUser){
      this.fetchprivileges(this.props.logInUser)
     }
  }
  addItem = () => {
    this.setState({
      isVisable: true,
      activeOperation: 'add',
      selectedItem: ''
    })
  }
  removedItem = item => {
    this.setState({
      isVisable: true,
      activeOperation: 'remove',
      selectedItem: item
    })
  }
  editItem = item => {
    this.setState({
      isVisable: true,
      activeOperation: 'edit',
      selectedItem: item
    })
  }

  selecteItem = item => {
    this.fetchPrivilages(item)
    this.setState({
      loader:true,
      activeOperation: 'showDetails',
      selectedItem: item
    })
  }
  onCloseModal = () => {
    this.setState({
      isVisable: false,
      activeOperation: '',
      selectedItem: ''
    })
    this.props.history.push('/roleManagement')
  }
  fetchPrivilages = item => {
    fetch(`/api/privileges?roleId=${item.id}`).then(response => {
      if (response.ok) {
        response.json().then(res => {
          if(res.status ==='success'){ 
            let privileges = res.data
            this.setState({
              isVisable: false
            }, ()=>{
              this.setState({
                itemPrivileges: privileges,
                loader:false,
                isVisable: true
              })
            })
          }
          else if(res?.statusCode === '440'){
            window.location.replace('/login')
          }
          else if(res.statusCode){
            var err = res?.message.split(':')
            err[1] =err[1].replace(')', "")
            this.props.dispatch(
              Notifications.error({
                message: this.props.translate(err[1]),
                autoDismiss: 5,
                location: this.props.location
              })
            )
          }
        })
      }else{
        throw response
      }
    })
    .catch(e => {
      this.props.dispatch(Notifications.error({
        message: 'somethingWentWrong',
        autoDismiss: 5
      }))
    })
  }
  fetchprivileges = (logInUser) => {
    if (!this.props.privileges.length) {
      // if (isOwnerOrDealer(logInUser.userType)) {
      if (logInUser.userType === -1) {
        fetch('/api/privileges?all=true').then(response => {
          if (response.ok) {
            response.json().then(res => {
              if(res.status ==='success'){ 
                let privileges = res.data
                this.props.dispatch(getPrivileges(privileges))
                let privilegeKeys = []
                privileges.map(item => {
                  privilegeKeys.push(item.key)
                })
                localStorage.setItem(
                  'privileges',
                  JSON.stringify({ ...privilegeKeys })
                )
  
                if (privileges.length) {
                  localStorage.setItem('userToken', '')
                  // this.props.history.push('/roleManagement')
                } else {
                  // this.props.history.push('/accounts')
                }
              }
              else if(res?.statusCode === '440'){
                window.location.replace('/login')
              }
              else if(res.statusCode){
                var err = res?.message.split(':')
                err[1] =err[1].replace(')', "")
                this.props.dispatch(
                  Notifications.error({
                    message: this.props.translate(err[1]),
                    autoDismiss: 5,
                    location: this.props.location
                  })
                )
              }
            })
          }else{
            throw response
          }
        }).catch(e => {
          this.props.dispatch(Notifications.error({
            message: 'somethingWentWrong',
            autoDismiss: 5
          }))
        })
      } else {
        fetch(`/api/privileges?roleId=${logInUser.roleId}`).then(
          response => {
            if (response.ok) {
              response.json().then(res => {
                if(res.status ==='success'){ 
                  let privileges= res.data
                  this.props.dispatch(getPrivileges(privileges))
                  let privilegeKeys = []
                  privileges.map(item => {
                    privilegeKeys.push(item.key)
                  })
                  localStorage.setItem(
                    'privileges',
                    JSON.stringify({ ...privilegeKeys })
                  )
  
                  if (privileges.length) {
                    localStorage.setItem('userToken', '')
                    // this.props.history.push('/roleManagement')
                  } else {
                    // this.props.history.push('/accounts')
                  }
                }
                else if(res?.statusCode === '440'){
                  window.location.replace('/login')
                }
                else if(res.statusCode){
                  var err = res?.message.split(':')
                  err[1] =err[1].replace(')', "")
                  this.props.dispatch(
                    Notifications.error({
                      message: this.props.translate(err[1]),
                      autoDismiss: 5,
                      location: this.props.location
                    })
                  )
                }
              })
            }
            else{
              throw response
            }
          })
           .catch(e => {
            this.props.dispatch(Notifications.error({
              message: 'somethingWentWrong',
              autoDismiss: 5
            }))
          })
      }
    }
  }
  render () {
    if (checkPrivileges('role')) {
      // if(this.props.roles && this.props.roles.length)
      if (this.props.logInUser){
      // if (this.props.logInUser && this.props.logInUser.userType === -1 && checkPrivileges('role') || this.props.logInUser.userType === 5) {
        // this.fetchprivileges()
        //   this.forReloadeUrl()
        //  }
        return (
          <Layout
            {...this.props}
            addItem={this.addItem}
            selecteItem={this.selecteItem}
            removedItem={this.removedItem}
            editItem={this.editItem}
          >
            {this.state.isVisable && this.state.activeOperation && !this.state.loader ? (
              <RoleModal
                activeOperation={this.state.activeOperation}
                selectedItem={this.state.selectedItem}
                onCloseModal={this.onCloseModal}
                itemPrivileges={this.state.itemPrivileges}
                privileges={this.props.privileges}
              />
            ) : (
                this.state.loader ? <Loader /> : 
                <EmptyState text={this.props.translate('pleaseSelectRole')} 
              />
            )}
          </Layout>
        )
      } else {
        fetch('/api/session?app=2bd5bd10345cd1555b14c221ec6e8647c46ee21e8ee45312', {
          headers: { Accept: 'application/json; odata=verbose' }
        }).then(response => {
          if (response.ok) {
            response.json().then(res => {
              if(res.status ==='success'){ 
                let userInfo = res.data
                this.props.setActiveLanguage(userInfo?.attributes?.lang || 'en')
                this.props.dispatch(logInUserInfo(userInfo))
                 //API privilige call
                 this.fetchprivileges(userInfo)
                // if (userInfo.userType !== -1) {
                //   window.location.replace('/login')
                // }
              }
              else if(res?.statusCode === '440'){
                window.location.replace('/login')
              }
              else if(res.statusCode){
                var err = res?.message.split(':')
                err[1] =err[1].replace(')', "")
                this.props.dispatch(
                  Notifications.error({
                    message: this.props.translate(err[1]),
                    autoDismiss: 5,
                    location: this.props.location
                  })
                )
              }
            })
          } else {
            this.props.history.push('/logout')
              throw response
            }
          }).catch(e => {
            this.props.dispatch(Notifications.error({
              message: 'somethingWentWrong',
              autoDismiss: 5
            }))
          })
        return null
      }
    } else {
      return <h1>Page Not Found</h1>
    }
  }
}

const mapStateToProps = state => ({
  ServerSetting: state.ServerSetting,
  logInUser: state.logInUsers,
  roles: state.roles,
  privileges: state.privileges
})

export default connect(mapStateToProps)(withLocalize(RoleManagement))
