import React, { Component } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import Account from './Pages/Accounts'
import Users from './Pages/Users'
import UserView from './Pages/UserView'
import Login from './Pages/Login'
import { LogOut } from './Pages/LogOut'
import Devices from './Pages/Devices'
import Groups from './Pages/Groups'
import Billings from './Pages/Billings'

import AccountManagements from './Pages/AccountManagements'
import ServerSettings from './Pages/ServerSettings'
import AccountSettings from './Pages/AccountSettings'
import SystemLogs from './Pages/SystemLogs'
import ActionLogs from './Pages/ActionLogs'
import RoleManagement from './Pages/Role'
import Plans from './Pages/Plans'
import ActivationCode from './Pages/ActivationCode'
import TemplateGenerator from './Pages/TemplateGenerator'
import Trash from './Pages/Trash'
import Coupon from './Pages/Coupon'
import WebHooks from './Pages/WebHooks'
import NotFoundPage from './Pages/NotFound'
import { withLocalize } from 'react-localize-redux'
import en from './lang/en.json'
import fr from './lang/fr.json'
import urdu from './lang/urdu.json'
import arabic from './lang/arabic.json'
import albanian from './lang/albanian.json'
import burmese from './lang/burmese.json'
import chinese from './lang/chinese.json'
import cambodian from './lang/cambodian.json'
import german from './lang/german.json'
import gujarati from './lang/gujarati.json'
import hebrew from './lang/hebrew.json'
import hindi from './lang/hindi.json'
import hungarian from './lang/hungarian.json'
import indonesian from './lang/indonesian.json'
import italian from './lang/italian.json'
import japanese from './lang/japanese.json'
import korean from './lang/korean.json'
import mongolian from './lang/mongolian.json'
import nepali from './lang/nepali.json'
import persian from './lang/persian.json'
import portuguese from './lang/portuguese.json'
import russian from './lang/russian.json'
import serbian from './lang/serbian.json'
import spanish from './lang/spanish.json'
import telugu from './lang/telugu.json'
import thai from './lang/thai.json'
import turkish from './lang/turkish.json'
import amharic from './lang/amharic.json'
import kurdish from './lang/Kurdish.json'
import './assets/main.scss'
import Notifications from 'react-notification-system-redux'
import axios from 'axios'

const onMissingTranslation = ({ translationId }) => translationId
class App extends Component {
  constructor (props) {
    super(props)
    this.state = {
      whiteLabling: '',
      callResponse: false,
      isDown: false,
      brandingCallInProcess: false
    }
    this.props.initialize({
      languages: [
        { name: 'English', code: 'en' },
        { name: 'French', code: 'fr' },
        { name: 'Urdu', code: 'ur' },
        { name: 'Arabic', code: 'ar' },
        { name: 'Albanian', code: 'sq' },
        { name: 'Burmese', code: 'my' },
        { name: 'Chinese', code: 'zh' },
        { name: 'Central Khmer', code: 'km' },
        { name: 'German', code: 'de' },
        { name: 'Gujarati', code: 'gu' },
        { name: 'Hebrew', code: 'he' },
        { name: 'Hindi', code: 'hi' },
        { name: 'Hungarian', code: 'hu' },
        { name: 'Indonesian', code: 'id' },
        { name: 'Italian', code: 'it' },
        { name: 'Japanese', code: 'ja' },
        { name: 'Korean', code: 'ko' },
        { name: 'Mongolian', code: 'mn' },
        { name: 'Nepali', code: 'ne' },
        { name: 'Persian', code: 'fa' },
        { name: 'Portuguese', code: 'pt' },
        { name: 'Russian', code: 'ru' },
        { name: 'Serbian', code: 'sr' },
        { name: 'Spanish', code: 'es' },
        { name: 'Telugu', code: 'te' },
        { name: 'Thai', code: 'th' },
        { name: 'Turkish', code: 'tr' },
        { name: 'Amharic', code: 'am' },
        { name: 'Kurdish', code: 'ku' }
      ],
      //translation: en,
      options: {
        renderToStaticMarkup: false,
        defaultLanguage: 'en',
        onMissingTranslation
      }
    })

    this.props.addTranslationForLanguage(en, 'en')
    this.props.addTranslationForLanguage(fr, 'fr')
    this.props.addTranslationForLanguage(urdu, 'ur')
    this.props.addTranslationForLanguage(arabic, 'ar')
    this.props.addTranslationForLanguage(albanian, 'sq')
    this.props.addTranslationForLanguage(burmese, 'my')
    this.props.addTranslationForLanguage(chinese, 'zh')
    this.props.addTranslationForLanguage(cambodian, 'km')
    this.props.addTranslationForLanguage(german, 'de')
    this.props.addTranslationForLanguage(gujarati, 'gu')
    this.props.addTranslationForLanguage(hebrew, 'he')
    this.props.addTranslationForLanguage(hindi, 'hi')
    this.props.addTranslationForLanguage(hungarian, 'hu')
    this.props.addTranslationForLanguage(indonesian, 'id')
    this.props.addTranslationForLanguage(italian, 'it')
    this.props.addTranslationForLanguage(japanese, 'ja')
    this.props.addTranslationForLanguage(korean, 'ko')
    this.props.addTranslationForLanguage(mongolian, 'mn')
    this.props.addTranslationForLanguage(nepali, 'ne')
    this.props.addTranslationForLanguage(persian, 'fa')
    this.props.addTranslationForLanguage(portuguese, 'pt')
    this.props.addTranslationForLanguage(russian, 'ru')
    this.props.addTranslationForLanguage(serbian, 'sr')
    this.props.addTranslationForLanguage(spanish, 'es')
    this.props.addTranslationForLanguage(telugu, 'te')
    this.props.addTranslationForLanguage(thai, 'th')
    this.props.addTranslationForLanguage(turkish, 'tr')
    this.props.addTranslationForLanguage(amharic, 'am')
    this.props.addTranslationForLanguage(kurdish, 'ku')
  }
  componentWillMount () {
    this.fetchBranding()
  }

  getImage = async (id, fieldName) => {
    try {
      const response = await axios.get(`/api/media/public/${id}`, {
        responseType: 'arraybuffer', // Ensure the response is treated as an array buffer
      });
      const filename = id;
      const parts = filename.split('.');
      const extension = parts[parts.length - 1];
  
      if (response.status === 200) {
        // Convert the array buffer to base64
        const base64Image = Buffer.from(response.data, 'binary').toString('base64');
        const imageData = `data:image/${extension};base64,${base64Image}`;
        
        // Update the state with the fetched image data
        this.setState(prevState => ({
          whiteLabling: {
            ...prevState.whiteLabling,
            [fieldName]: imageData // Update the field corresponding to the fetched image
          } 
        }));
        if(fieldName === 'favIcon'){
          let domElements = document.getElementById('favicon');
            if (imageData && imageData && domElements) {
              domElements.setAttribute('href', imageData);
            }
        }
         
        
      } else {
        console.error('Failed to fetch image:', response.status, response.statusText);
      }
    } catch (error) {
      console.error('Error in getImage:', error);
    }
  };
  
  fetchBranding = () => {
    fetch(`/api/accounts/labeling?hostUrl=${window.location.host}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    }).then(response => {
      if (response.ok) {
        response.json().then(res => {
          if (res.status === 'success') {
            // if(process.env.REACT_APP_SERVER_LOCAL === "true" ){
              this.getImage(res.data.favIcon, 'favIcon');
              this.getImage(res.data.logo, 'logo');
              this.getImage(res.data.logInPageImage, 'logInPageImage');
              this.setState({
                whiteLabling: { ...res.data },
                callResponse: true,
                brandingCallInProcess: false
              })
            // }else{
            //   this.setState({
            //     whiteLabling: { ...res.data },
            //     callResponse: true,
            //     brandingCallInProcess: false
            //   })
            // }
            document.title = res.data.serviceTitle;
            
            // Update the favicon
            // let domElements = document.getElementById('favicon');
            // if (this.state.whiteLabling && this.state.whiteLabling.favIcon && domElements) {
            //   domElements.setAttribute('href', this.state.whiteLabling.favIcon);
            // }
          } else if (res?.statusCode === '440') {
            window.location.replace('/login')
          } else if (res.statusCode) {
            var err = res?.message.split(':')
            err[1] = err[1].replace(')', "")
            this.setState({
              callResponse: true
            })
          } else {
            this.setState({
              callResponse: true,
              brandingCallInProcess: false
            })
          }
        })
      } else {
        //alert('Server is Down')
        this.setState({
          callResponse: true
        })
        console.log('Server is Down')
        throw response
      }
    }).catch(e => {
      console.log(e)
    })
  }
  
  render () {
    if (
      window.location.pathname === '/logout' &&
      this.state.brandingCallInProcess
    ) {
      this.setState(
        {
          brandingCallInProcess: true
        },
         () => this.fetchBranding()
      )
    }
    if (this.state.callResponse) {
      return (
        <div className='fms'>
          <Switch>
            <Route
              exact
              path='/'
              render={routeProps => (
                <Redirect
                  from='/'
                  to='/login'
                /> /*<Users {...routeProps} {...this.state} />*/
              )}
            />
            {/* <Route
              exact
              path='/dashboard'
              render={routeProps => (
                <Dashboard {...routeProps} {...this.state} />
              )}
            /> */}
            {/* <Route
              exact
              path='/accounts'
              render={routeProps => <Account {...routeProps} {...this.state} />}
            />              */}
            <Route
              exact
              path='/accountSettings'
              render={routeProps => <AccountSettings {...routeProps} {...this.state} />}
            />
            <Route
              exact
              path='/users'
              render={routeProps => <Users {...routeProps} {...this.state} />}
            />
            <Route
              exact
              path='/users/view'
              render={routeProps => (
                <UserView {...routeProps} {...this.state} />
              )}
            />
            <Route
              exact
              path='/users/:id'
              render={routeProps => <Users {...routeProps} {...this.state} />}
            />
            <Route
              exact
              path='/users/edit/:userId'
              render={routeProps => (
                <Users {...routeProps} {...this.state} addUserScreen={true} />
              )}
            />
            <Route
              exact
              path='/units'
              render={routeProps => <Devices {...routeProps} {...this.state} />}
            />
            <Route
              exact
              path='/units/:id'
              render={routeProps => <Devices {...routeProps} {...this.state} />}
            />
            <Route
              exact
              path='/units/create/:userId'
              render={routeProps => (
                <Devices {...routeProps} {...this.state} addUnitScreen={true} />
              )}
            />
            
            <Route
              exact
              path='/billings'
              render={routeProps => <Billings {...routeProps} {...this.state} />}
            />
            <Route
              exact
              path='/groups'
              render={routeProps => <Groups {...routeProps} {...this.state} />}
            />
            <Route
              exact
              path='/groups/:id'
              render={routeProps => <Groups {...routeProps} {...this.state} />}
            />
            <Route
              exact
              path='/accountManagements'
              render={routeProps => (
                <AccountManagements {...routeProps} {...this.state} />
              )}
            />
            <Route
              exact
              path='/serverSettings'
              render={routeProps => (
                <ServerSettings {...routeProps} {...this.state} />
              )}
            />
            <Route
              exact
              path='/login'
              render={routeProps => <Login {...routeProps} {...this.state} />}
            />
            <Route
              exact
              path='/logout'
              render={routeProps => <LogOut {...routeProps} {...this.state} />}
            />
            <Route
              exact
              path='/systemLogs'
              render={routeProps => (
                <SystemLogs {...routeProps} {...this.state} />
              )}
            />
            <Route
              exact
              path='/actionLogs'
              render={routeProps => (
                <ActionLogs {...routeProps} {...this.state} />
              )}
            />
            <Route
              exact
              path='/roleManagement'
              render={routeProps => (
                <RoleManagement {...routeProps} {...this.state} />
              )}
            />
            <Route
              exact
              path='/roleManagement/:id'
              render={routeProps => (
                <RoleManagement {...routeProps} {...this.state} />
              )}
            />
            <Route
              exact
              path='/plans'
              render={routeProps => <Plans {...routeProps} {...this.state} />}
            />
            <Route
              exact
              path='/activationCode'
              render={routeProps => (
                <ActivationCode {...routeProps} {...this.state} />
              )}
            />
            <Route
              exact
              path='/templateGenerator'
              render={routeProps => (
                <TemplateGenerator {...routeProps} {...this.state} />
              )}
            />
             <Route
              exact
              path='/trash'
              render={routeProps => (
                <Trash {...routeProps} {...this.state} />
              )}
            />
            <Route
                exact
                path='/coupon'
                render={routeProps => (
                  <Coupon {...routeProps} {...this.state} />
              )}
            />
              <Route
                exact
                path='/webHooks'
                render={routeProps => (
                  <WebHooks {...routeProps} {...this.state} />
              )}
            />
            <Route path='*' component={NotFoundPage} />
          </Switch>
        </div>
      )
    } else {
      if (this.state.isDown) {
        return (
          <h2 style={{ textAlign: 'center' }}>
            Oops Something Went Wrong Try Again
          </h2>
        )
      } else {
        return null
      }
    }
  }
}
export default withLocalize(App)
